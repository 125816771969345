// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP

import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { AnalyticsWrapper } from "./analytics/analytics-wrapper"
import { DSCCHeader, GlobalLayout, ROPHeader } from "./components"
import { AuthCallback, LoginRedirect, Logout, MockCCS } from "./oauth"
import {
  AuthenticatedDSCCRoutes,
  AuthenticatedROPRoutes,
  Login,
  NotFound,
  OnPremCertRedirect,
  ShellRoute,
  Unauthorized,
} from "./routes"
import { routes } from "./shell-routes"
import { Products } from "./utils/constants"
import { env } from "./utils/env"

const getAppRoutes = (product) => {
  let appRoutes = []
  switch (product) {
    case Products.DSCC: {
      appRoutes = [
        {
          element: <GlobalLayout />,
          children: [
            {
              element: <LoginRedirect />,
              children: [
                {
                  path: "/*",
                  element: (
                    <AnalyticsWrapper>
                      <AuthenticatedDSCCRoutes />
                    </AnalyticsWrapper>
                  ),
                },
              ],
            },
            { path: "login", element: <Login /> },
            { path: "auth", element: <AuthCallback /> },
            { path: "logout", element: <Logout /> },
            { path: routes.unauthorized.path, element: <Unauthorized /> },
            {
              path: "ccs/*",
              element: (
                <MockCCS enabled={process.env.NODE_ENV === "development"} />
              ),
            },
            {
              path: "*",
              element: (
                <ShellRoute Header={DSCCHeader}>
                  <NotFound />
                </ShellRoute>
              ),
            },
          ],
        },
      ]
      break
    }
    case Products.ONPREM: {
      appRoutes = [
        {
          element: <GlobalLayout />,
          children: [
            {
              element: <LoginRedirect />,
              children: [
                {
                  path: "/*",
                  element: <AuthenticatedDSCCRoutes />,
                },
              ],
            },
            { path: "login", element: <Login /> },
            { path: "auth", element: <AuthCallback /> },
            { path: "logout", element: <Logout /> },
            { path: routes.unauthorized.path, element: <Unauthorized /> },
            { path: "accept-certificate", element: <OnPremCertRedirect /> },
            {
              path: "ccs/*",
              element: (
                <MockCCS
                  enabled={
                    process.env.NODE_ENV === "development" ||
                    env.REACT_APP_MOCK_AUTH === "true"
                  }
                />
              ),
            },
            {
              path: "*",
              element: (
                <ShellRoute Header={DSCCHeader}>
                  <NotFound />
                </ShellRoute>
              ),
            },
          ],
        },
      ]
      break
    }
    case Products.ROP: {
      appRoutes = [
        {
          element: <GlobalLayout />,
          children: [
            {
              element: <LoginRedirect />,
              children: [
                {
                  path: "/*",
                  element: <AuthenticatedROPRoutes />,
                },
              ],
            },
            { path: "login", element: <Login /> },
            { path: "auth", element: <AuthCallback /> },
            { path: "logout", element: <Logout /> },
            {
              path: "ccs/*",
              element: (
                <MockCCS enabled={process.env.NODE_ENV === "development"} />
              ),
            },
            {
              path: "*",
              element: (
                <ShellRoute Header={ROPHeader}>
                  <NotFound />
                </ShellRoute>
              ),
            },
          ],
        },
      ]
      break
    }
    default: {
      break
    }
  }
  return appRoutes
}

export default function AppRouter() {
  return (
    <RouterProvider
      router={createBrowserRouter(getAppRoutes(env.REACT_APP_PRODUCT))}
    />
  )
}
