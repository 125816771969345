// (c) Copyright 2025 Hewlett Packard Enterprise Development LP

export const SHELL_RTM = {
  INIT: "shell:rtm-init",
  CLEANUP: "shell:rtm-cleanup",
}

export const TOKEN_REFRESH_EVENT_TYPE = "shell:token-refresh"

const TOKEN_REFRESH_EVENT = new CustomEvent(TOKEN_REFRESH_EVENT_TYPE)

export function dispatchTokenRefreshEvent(target = window) {
  target.dispatchEvent(TOKEN_REFRESH_EVENT)
}

export function navigationEventDetail(url, type) {
  const parts = new URL(url)
  return {
    type,
    hash: parts.hash,
    hostname: parts.hostname,
    href: parts.href,
    origin: parts.origin,
    pathname: parts.pathname,
    search: parts.search,
  }
}

export const NavigationEventType = {
  DENIED: "shell:path-not-allowed",
  MICROAPP: "microapp:navigation",
  SHELL: "shell:navigation",
}

export function dispatchNavigationEvent({
  eventType = NavigationEventType.SHELL,
  target = window,
  url,
  type,
}) {
  target.dispatchEvent(
    new CustomEvent(eventType, {
      detail: navigationEventDetail(url, type),
    })
  )
}
