// (c) Copyright 2024-2025 Hewlett Packard Enterprise Development LP
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Grommet } from "grommet"
import { hpe } from "grommet-theme-hpe"
import { createPortal } from "react-dom"
import AppRouter from "./app-router"
import { VercelFlags } from "./components/vercel-flags"
import { Help } from "./contextual-help"
import { LaunchDarklyProvider } from "./contexts/launchdarkly-context"
import { StorageEventHandler } from "./oauth"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cache for 10 mins to prevent fetching too often
      staleTime: 10 * 60 * 1000,
      // disable retries in Cypress environment to enable failure testing
      retry: window.Cypress ? false : 3,
    },
  },
})

const helpEl = document.getElementById("help")

export function App({ ldClient }) {
  return (
    <LaunchDarklyProvider ldClient={ldClient}>
      <QueryClientProvider client={queryClient}>
        <Grommet theme={hpe}>
          <StorageEventHandler />
          <AppRouter />
          {createPortal(<Help />, helpEl)}
        </Grommet>
        <ReactQueryDevtools initialIsOpen={false} />
        <VercelFlags />
      </QueryClientProvider>
    </LaunchDarklyProvider>
  )
}
