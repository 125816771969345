// (c) Copyright 2025 Hewlett Packard Enterprise Development LP

import { FlagValues } from "@vercel/flags/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { isVercelEnv } from "../utils/env"

export function VercelFlags() {
  const flags = useFlags()

  let vercelFlags = {}
  if (isVercelEnv) {
    Object.entries(flags).forEach(([key, value]) => {
      if (typeof value === "boolean") {
        vercelFlags[key] = value
      }
    })
    vercelFlags.Product = "DSCC"
  }

  if (!isVercelEnv) {
    return null
  }

  return <FlagValues values={vercelFlags} />
}
