// (c) Copyright 2025 Hewlett Packard Enterprise Development LP

import { parse } from "cookie"
import { Products } from "./constants"

export function extractOrigin(source) {
  try {
    return new URL(source).origin
  } catch {
    return ""
  }
}

const {
  REACT_APP_AMPLITUDE_PROJECT_ID,
  REACT_APP_AMPLITUDE_PROJECT_KEY,
  REACT_APP_API_URL,
  REACT_APP_APP_INSTANCE_ID,
  REACT_APP_AUTH_TOKEN,
  REACT_APP_CCS_LOGIN,
  REACT_APP_CONSOLE_URL,
  REACT_APP_HELP_HOST,
  REACT_APP_LD_CLIENTID,
  REACT_APP_LD_MOCK,
  REACT_APP_MOCK_AUTH,
  REACT_APP_OAUTH_WELL_KNOWN_ENDPOINT,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_PROXY_MIRAGE,
} = window.$env

let { REACT_APP_PRODUCT } = window.$env

export const isVercelEnv = process.env.REACT_APP_VERCEL_ENV !== undefined

if (isVercelEnv) {
  const { cookie } = window.document
  const overrideCookie = parse(cookie)?.["vercel-flag-overrides"]
  const vercelFlags = overrideCookie ? JSON.parse(overrideCookie) : {}
  if (vercelFlags?.Product) {
    REACT_APP_PRODUCT = vercelFlags.Product
  }
}

// extract base oauth authority URL from well-known
const REACT_APP_OIDC_AUTHORITY = extractOrigin(
  REACT_APP_OAUTH_WELL_KNOWN_ENDPOINT
)

// combine console base URL with asset URI for full micro app URL
const uriMap = {
  REACT_APP_ALLOW_DENY_MANAGER_URI: "REACT_APP_ALLOW_DENY_MANAGER_SRC",
  REACT_APP_ANNOUNCEMENTS_URI: "REACT_APP_ANNOUNCEMENTS_SRC",
  REACT_APP_APOLLO_OBJECT_URI: "REACT_APP_APOLLO_OBJECT_SRC",
  REACT_APP_ATLAS_URI: "REACT_APP_ATLAS_SRC",
  REACT_APP_AUDIT_URI: "REACT_APP_AUDIT_SRC",
  REACT_APP_BLOCK_URI: "REACT_APP_BLOCK_SRC",
  REACT_APP_DATA_ACCESS_URI: "REACT_APP_DATA_ACCESS_SRC",
  REACT_APP_DATA_OBSERVABILITY_URI: "REACT_APP_DATA_OBSERVABILITY_SRC",
  REACT_APP_DOM_URI: "REACT_APP_DOM_SRC",
  REACT_APP_DUAL_AUTH_URI: "REACT_APP_DUAL_AUTH_SRC",
  REACT_APP_EMAIL_URI: "REACT_APP_EMAIL_SRC",
  REACT_APP_FILE_DOM_URI: "REACT_APP_FILE_DOM_SRC",
  REACT_APP_FILE_MANAGER_URI: "REACT_APP_FILE_MANAGER_SRC",
  REACT_APP_GROUPS_URI: "REACT_APP_GROUPS_SRC",
  REACT_APP_HOMEFLEET_URI: "REACT_APP_HOMEFLEET_SRC",
  REACT_APP_ISSUES_URI: "REACT_APP_ISSUES_SRC",
  REACT_APP_KUBERNETES_SERVICE_URI: "REACT_APP_KUBERNETES_SERVICE_SRC",
  REACT_APP_LINKS_URI: "REACT_APP_LINKS_SRC",
  REACT_APP_NIMBLE_URI: "REACT_APP_NIMBLE_SRC",
  REACT_APP_OBJECT_STORAGE_URI: "REACT_APP_OBJECT_STORAGE_SRC",
  REACT_APP_PCAI_URI: "REACT_APP_PCAI_SRC",
  REACT_APP_PCBE_URI: "REACT_APP_PCBE_SRC",
  REACT_APP_PRIMERA_URI: "REACT_APP_PRIMERA_SRC",
  REACT_APP_REFERENCE_URI: "REACT_APP_REFERENCE_SRC",
  REACT_APP_SDS_URI: "REACT_APP_SDS_SRC",
  REACT_APP_SEARCH_URI: "REACT_APP_SEARCH_SRC",
  REACT_APP_SECRETS_URI: "REACT_APP_SECRETS_SRC",
  REACT_APP_SETUP_URI: "REACT_APP_SETUP_SRC",
  REACT_APP_SFM_URI: "REACT_APP_SFM_SRC",
  REACT_APP_SHAPESHIFTER_URI: "REACT_APP_SHAPESHIFTER_SRC",
  REACT_APP_STATUS_URI: "REACT_APP_STATUS_SRC",
  REACT_APP_TASKS_URI: "REACT_APP_TASKS_SRC",
  REACT_APP_WEBHOOKS_URI: "REACT_APP_WEBHOOKS_SRC",
  REACT_APP_ZERTO_URI: "REACT_APP_ZERTO_SRC",
}

// Vercel URI mapping
const vercelURIMap = {
  REACT_APP_TASKS_URI: "tasks_assets",
}

const getEnv = (key) => {
  if (isVercelEnv) {
    // If the application is deployed to vercel, the assets will be served .
    let uriPath = vercelURIMap[key]
    if (uriPath) {
      return uriPath
    }
  }
  return window.$env[key]
}

const createAssetSrc = (key) => {
  if (isVercelEnv) {
    return `${window.location.origin}/${
      getEnv(key) || getEnv("REACT_APP_REFERENCE_URI")
    }`
  }
  return `${REACT_APP_CONSOLE_URL}/${
    getEnv(key) || getEnv("REACT_APP_REFERENCE_URI")
  }`
}

export const assetPaths = {}

for (let key in uriMap) {
  assetPaths[uriMap[key]] = createAssetSrc(key)
}

export const isDSCC = () => {
  return REACT_APP_PRODUCT === Products.DSCC
}

export const isONPREM = () => {
  return REACT_APP_PRODUCT === Products.ONPREM
}

export const isROP = () => {
  return REACT_APP_PRODUCT === Products.ROP
}

export const env = {
  REACT_APP_AMPLITUDE_PROJECT_ID,
  REACT_APP_AMPLITUDE_PROJECT_KEY,
  REACT_APP_API_URL,
  REACT_APP_APP_INSTANCE_ID,
  REACT_APP_AUTH_TOKEN,
  REACT_APP_CCS_LOGIN,
  REACT_APP_CONSOLE_URL,
  REACT_APP_HELP_HOST,
  REACT_APP_LD_CLIENTID,
  REACT_APP_LD_MOCK,
  REACT_APP_MOCK_AUTH,
  REACT_APP_OIDC_AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_PRODUCT,
  REACT_APP_PROXY_MIRAGE,
}
